import { AngularJSDispatch } from "../../core/webapp-channel";
import { useWebappChanneDispatchEvent } from "./useWebappChannelEvent";

type SocketEvent = AngularJSDispatch["SOCKET_EVENT_RECEIVED"];

type SocketPayloadMap = {
  [k in SocketEvent["type"]]: Extract<SocketEvent, { type: k }>["payload"];
};

const useMultipleSocketEvent = <$SocketEventKey extends SocketEvent["type"]>(params: {
  keys: $SocketEventKey[];
  onEvent: (event: SocketPayloadMap[$SocketEventKey]) => void;
}) => {
  useWebappChanneDispatchEvent({
    eventName: "SOCKET_EVENT_RECEIVED",
    onEvent: (value) => {
      if (params.keys.includes(value.type as $SocketEventKey)) {
        params.onEvent(value.payload as SocketPayloadMap[$SocketEventKey]);
      }
    },
  });
};

export default useMultipleSocketEvent;
