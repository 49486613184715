import React from "react";
import { match, P } from "ts-pattern";
import type { DatabaseApiService } from "../../../public/admin/scripts/services/db";
import LoadingPage from "../shared/components/LoadingPage";
import { useWebappChannelPayloadEvent } from "../shared/hooks/useWebappChannelEvent";
import webappChannel from "./webapp-channel";

export const DatabaseApiContext = React.createContext<DatabaseApiService>(null as any);

export const DatabaseApiProvider = (props: { children: React.ReactNode }) => {
  const [DatabaseApi, setDatabaseApi] = React.useState<DatabaseApiService | null>(null);

  useWebappChannelPayloadEvent({
    eventName: "LOCAL_DB_SET",
    runInitial: true,
    onEvent: () => {
      const info = webappChannel.getPayload("LOCAL_DB_SET") ?? null;
      setDatabaseApi(info);
    },
  });

  return match(DatabaseApi)
    .with(P.nullish, () => <LoadingPage />)
    .with(P.not(null), (value) => (
      <DatabaseApiContext.Provider value={value}>{props.children}</DatabaseApiContext.Provider>
    ))
    .exhaustive();
};
