import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import BackspaceIcon from "../icons/BackspaceIcon";
import { phoneFormatter } from "../utils/phone-formatter";
import PhoneNumberInput from "./PhoneNumberInput";
import SelectMenu from "./SelectMenu";

interface Props {
  dialButtonContent: React.ReactNode;
  dialButtonLeftIcon?: React.ReactElement;
  onDialNumber: (E164number: string) => void;
}

const Dialpad = (props: Props) => {
  const [phonenumber, setPhoneNumber] = useState("");
  const [prefix, setPrefix] = useState("+1");
  const addToNumber = (value: number | string) => {
    if (phonenumber.length >= 10) {
      return;
    }
    setPhoneNumber((curr) => `${curr}${value}`);
  };
  const removeFromNumber = () => {
    setPhoneNumber((curr) => curr.slice(0, curr.length - 1));
  };
  return (
    <Flex direction={"column"} gap={4} w={"60%"}>
      <Flex gap={2}>
        <SelectMenu
          label="Prefix"
          onChange={(e) => {
            if (e !== undefined) {
              setPrefix(e);
            }
          }}
          options={[
            { label: "+1", value: "+1" },
            { label: "+972", value: "+972" },
          ]}
          value={prefix}
          closeOnSelect={true}
        />
        <PhoneNumberInput
          value={phonenumber}
          textAlign={"center"}
          onChange={(e) => setPhoneNumber(e as string)}
          placeholder={"Enter number or paste..."}
          autoFocus={true}
          borderWidth={0}
        />
      </Flex>
      <Flex direction={"row"} justifyContent={"space-around"}>
        <DialPadNumber onClick={addToNumber} value={1} />
        <DialPadNumber onClick={addToNumber} value={2} />
        <DialPadNumber onClick={addToNumber} value={3} />
      </Flex>
      <Flex direction={"row"} justifyContent={"space-around"}>
        <DialPadNumber onClick={addToNumber} value={4} />
        <DialPadNumber onClick={addToNumber} value={5} />
        <DialPadNumber onClick={addToNumber} value={6} />
      </Flex>
      <Flex direction={"row"} justifyContent={"space-around"}>
        <DialPadNumber onClick={addToNumber} value={7} />
        <DialPadNumber onClick={addToNumber} value={8} />
        <DialPadNumber onClick={addToNumber} value={9} />
      </Flex>
      <Flex direction={"row"} justifyContent={"space-around"}>
        <DialPadNumber onClick={addToNumber} value={"*"} />
        <DialPadNumber onClick={addToNumber} value={0} />
        <DialPadNumber onClick={addToNumber} value={"#"} />
      </Flex>
      <DialPadNumber value={""} onClick={removeFromNumber}>
        <BackspaceIcon boxSize={6} />
      </DialPadNumber>
      <Button
        leftIcon={props.dialButtonLeftIcon}
        colorScheme={"blue"}
        disabled={!phoneFormatter.isValidNumber(`${prefix}${phonenumber}`)}
        onClick={() => props.onDialNumber(`${prefix}${phonenumber}`)}
      >
        {props.dialButtonContent}
      </Button>
    </Flex>
  );
};

const DialPadNumber = (props: {
  onClick: (value: number | string) => void;
  value: number | string;
  children?: React.ReactNode;
}) => {
  return (
    <Button
      height={"50px"}
      background={"white"}
      flexGrow={1}
      onClick={() => props.onClick(props.value)}
    >
      {props.value}
      {props.children}
    </Button>
  );
};

export default Dialpad;
