import React from "react";

import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Messages } from "../../../core/api";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import { sortingFns } from "../../../shared/utils/tanstack-table";
import { getRelatedEntityFromFax, getViewFaxButton } from "../utils/fax-utils";
import { Instant } from "@js-joda/core";
import { TableCellBadge } from "../../communication/components/TableCellBadge";
import EntityCard, { Entity } from "../../../shared/components/EntityCard";
import ViewFaxButton, { ViewFax } from "./ViewFaxButton";
import UpdateActionFaxButton from "./UpdateActionFaxButton";
import { FaxDetailsId } from "../../../shared/schema/schema";

interface FaxDataRow {
  type: Messages["FaxType"];
  createdAt: Instant;
  createdBy: string | null;
  sentFrom: string;
  sentTo: string;
  entity: Entity;
  status: Messages["FaxStatus"];
  viewFaxButton: ViewFax;
  updateActionButton: Messages["DashboardFax"];
}

function toDataRow(fax: Messages["DashboardFax"]): FaxDataRow {
  const entity = getRelatedEntityFromFax(fax);
  const viewFaxButton = getViewFaxButton(fax);

  return {
    type: fax.type,
    createdAt: fax.createdAt,
    createdBy: fax.createdByUserName,
    sentFrom: fax.sender,
    sentTo: fax.recipient,
    entity: entity,
    status: fax.status,
    viewFaxButton: viewFaxButton,
    updateActionButton: fax,
  };
}

const faxStatusTextToColor = {
  failed: "red",
  processing: "yellow",
  success: "green",
};

const paginationPageSizes = [10, 20, 30, 40, 50];

export function FaxesTable(props: {
  faxes: Messages["DashboardFax"][];
  onUpdateStatus: (faxId: FaxDetailsId) => void;
}) {
  const [sorting, setSorting] = React.useState<SortingState>([{ id: "createdAt", desc: true }]);

  const data = React.useMemo(() => props.faxes.map(toDataRow), [props.faxes]);

  const columnHelper = createColumnHelper<FaxDataRow>();
  const columns = [
    columnHelper.accessor("entity", {
      cell: (info) => <EntityCard entity={info.getValue()} />,
      header: () => <span>Entity</span>,
    }),
    columnHelper.accessor("type", {
      cell: (info) => info.getValue(),
      header: () => <span>Type</span>,
    }),
    columnHelper.accessor("createdAt", {
      sortingFn: sortingFns.instant,
      cell: (info) => <Text>{dateFormatter.toDateOrDateTime(info.getValue())}</Text>,
      header: () => <span>Created at</span>,
    }),
    columnHelper.accessor("createdBy", {
      cell: (info) => info.getValue(),
      header: () => <span>Created by</span>,
    }),
    columnHelper.accessor("sentFrom", {
      cell: (info) => phoneFormatter.formatNational(info.getValue()),
      header: () => <span>Sent from</span>,
    }),
    columnHelper.accessor("sentTo", {
      cell: (info) => phoneFormatter.formatNational(info.getValue()),
      header: () => <span>Sent to</span>,
    }),
    columnHelper.accessor("status", {
      cell: (info) => (
        <TableCellBadge
          color={faxStatusTextToColor[info.getValue()]}
          text={info.getValue().toLocaleUpperCase()}
        />
      ),
      header: () => <span>Fax Status</span>,
    }),
    columnHelper.accessor("updateActionButton", {
      cell: (info) => (
        <UpdateActionFaxButton
          fax={info.getValue()}
          onUpdateAction={props.onUpdateStatus}
        ></UpdateActionFaxButton>
      ),
      header: () => "",
    }),
    columnHelper.accessor("viewFaxButton", {
      cell: (info) => <ViewFaxButton viewFax={info.getValue()}></ViewFaxButton>,
      header: () => "",
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const createHeaderFromColumn = (header: Header<FaxDataRow, any>) => {
    return (
      <Th
        key={header.id}
        colSpan={header.colSpan}
        onClick={header.column.getToggleSortingHandler()}
        cursor="pointer"
        transition="100ms ease-in-out"
        _hover={{ bg: "gray.100" }}
        borderTopRadius="md"
      >
        {header.isPlaceholder ? null : (
          <div className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}>
            <Flex justifyContent="space-between" alignItems="center">
              {flexRender(header.column.columnDef.header, header.getContext())}
              {{
                asc: <ArrowUpIcon />,
                desc: <ArrowDownIcon />,
              }[header.column.getIsSorted() as string] ?? null}
            </Flex>
          </div>
        )}
      </Th>
    );
  };

  const createTableRow = (row: Row<FaxDataRow>) => {
    return (
      <Tr key={row.id} borderBottomColor={"blue.100"} fontWeight={600} _hover={{ bg: "gray.50" }}>
        {row.getVisibleCells().map((cell) => {
          return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>;
        })}
      </Tr>
    );
  };

  return (
    <Stack spacing={1}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return createHeaderFromColumn(header);
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>{table.getRowModel().rows.map((row) => createTableRow(row))}</Tbody>
        </Table>
      </TableContainer>
      <Flex direction="row" dir="ltr" alignItems="center" gap={8}>
        <Stack direction="row" dir="ltr" alignItems="center" spacing={1}>
          <Button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
            {"«"}
          </Button>
          <Button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
            {"‹"}
          </Button>
          <Button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            {"›"}
          </Button>
          <Button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {"»"}
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Text fontSize="md">Page</Text>
          <Stack direction="row" alignItems="center" w="150px">
            <Text as="b" fontSize="md">
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </Text>
            <Text>({table.getRowModel().rows.length} Rows)</Text>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Text fontSize="md" w="100px">
            Go to page:
          </Text>
          <Input
            w="60px"
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </Stack>
        <Menu>
          <MenuButton as={Button}>Show {table.getState().pagination.pageSize}</MenuButton>
          <MenuList>
            {paginationPageSizes.map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize} onClick={() => table.setPageSize(pageSize)}>
                Show {pageSize}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Stack>
  );
}

export default FaxesTable;
