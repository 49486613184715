import { io } from "socket.io-client";
import { dispatchSocketMessageToWebappChannel } from "../../modules/react-adapter/react-adapter.module"

/* @ngInject */
export function Socket($rootScope, Consts, $injector, $timeout, $state, DatabaseApi, SweetAlert, Storage, $window, toaster) {

    function init(loginResponse) {
        let authToken = loginResponse.authToken;
        let reconnectAttempts = 0;

        var socket = io(Consts.socketApi, {
            withCredentials: false
        });

        socket.on('connect', function(){
            console.log('[socket.io] socket connected');
            socket.emit('auth', {token: authToken});
        });

        socket.on('auth:error', async (error) => {
            if (reconnectAttempts === 5) {
                console.log('[socket.io] socket auth error', JSON.stringify(error));
                return;
            }

            if (reconnectAttempts > 0) {
                console.log(`[socket.io] socket auth error. Retrying in ${reconnectAttempts}s`, error);
                await new Promise(resolve => setTimeout(resolve, reconnectAttempts * 1000));
            }

            if (reconnectAttempts === 0) {
                console.log(`[socket.io] socket auth error.`, error);
            }

            reconnectAttempts++;

            socket.disconnect();

            const authTokenFromStorage = Storage.getObject('user').authToken;

            if (authTokenFromStorage) {
                authToken = authTokenFromStorage;
            }

            socket.connect();
        })

        socket.on('auth:done', function(){
            reconnectAttempts = 0;
            console.log('[socket.io] socket auth success');
        });

        socket.on('caregiver:online', function(data){
            $injector.get('DatabaseApi').setCaregiverOnline(data.caregiverId.toString());
            $rootScope.$broadcast('caregiver_changed',{id: data.caregiverId});
        });

        socket.on('caregiver:offline', function(data){
            $injector.get('DatabaseApi').setCaregiverOffline(data.caregiverId.toString());
            $rootScope.$broadcast('caregiver_changed',{id: data.caregiverId});
        });

        socket.onAny(dispatchSocketMessageToWebappChannel);

        socket.on('VisitUpdated', function(data){
            var parsedData = JSON.parse(data);
            $rootScope.$broadcast('visit_id_changed', {visitId: parsedData.visitId});
        });

        socket.on('ClusterUpdated', function(data){

            var parsedData = JSON.parse(data);
            parsedData = parsedData.cluster;
            var isChange = false;
            parsedData.visit1.assignedCoordinators.forEach(function (coordinator) {
                if(DatabaseApi.getReleventMemberIds().indexOf(coordinator) > -1) {
                    isChange = true;
                }
            });
            parsedData.visit2.assignedCoordinators.forEach(function (coordinator) {
                if(DatabaseApi.getReleventMemberIds().indexOf(coordinator) > -1) {
                    isChange = true;
                }
            });

            if(isChange) {
                DatabaseApi.clusterUpdated(parsedData);
            }

        });


        socket.on('ChatRoomUpdated', function(data){
            var parsedData = JSON.parse(data);
            DatabaseApi.getMessagesByIds(parsedData.chatRoomId, parsedData.chatRoomData,'socket')
                .then(function (res) {
                    if( res && res.id ) $rootScope.$broadcast('chat_room_updated', res);
                });
        });

        socket.on('CaregiversUpdated', function(data){
            var parsedData = JSON.parse(data);
            var isPending = !!(parsedData.caregiverData &&
                parsedData.caregiverData.status === 'JOIN_REQUEST');

            $injector.get('DatabaseApi')
                .caregiversChanged(parsedData.caregiverId, parsedData.caregiverData, isPending)
                .then(function () {
                    $rootScope.$broadcast('caregiver_changed',{id: parsedData.caregiverId});
                });
        });

        socket.on('ScheduledVisitEditRequestChange', function () {
            DatabaseApi.getVisitPendingEditRequests().then(function () {
                $rootScope.$broadcast('VisitPendingRequestsUpdated');
            });
            DatabaseApi.getVisitArchivedEditRequests().then(function () {
                $rootScope.$broadcast('VisitArchivedRequestsUpdated');
            });
        });

        socket.on('PatientUpdated', function (data) {
            var parsedData = JSON.parse(data);
            $rootScope.$broadcast('PatientUpdated', parsedData);
        });

        socket.on('ScoreChanged', function(data){
          var parsedData = JSON.parse(data);
          if(parsedData.agencyMemberId === $rootScope.agencyMemberId){
                $rootScope.sweepScore = parsedData.points;
                if($rootScope.sweepScore < 0) $rootScope.sweepScore = 0;
            }
        });

        socket.on('general:reload', function(data){
            if(!data || data.id === $rootScope.agencyMemberId){
                Storage.softClear();
                $window.location.reload();
            }
        });

        socket.on('general:reload-pop', function(){


            SweetAlert.swal({
                title: "System Update Available",
                text: "Confirm Refresh",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, refresh",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function (isConfirm) {
                if (isConfirm) {
                    Storage.softClear();
                    $window.location.reload();
                }
            });


        });

        socket.on('PatientAlertAdded', function (data) {
            $rootScope.$broadcast('PatientAlertAdded', data);
          });

        socket.on('disconnect', function(){
            console.log('socket disconnected');
        });

        socket.on("AgencyMemberWasMentioned", function (data) {
          const parsedData = JSON.parse(data);
          const { mentioningAgencyMemberName, noteId, mentionId } = parsedData;

          if (!$rootScope.mentionesCount) {
            $rootScope.mentionesCount = 0;
          }
          $rootScope.mentionesCount += 1;

          toaster.pop({
            type: "note",
            title: `${mentioningAgencyMemberName} tagged you in a note`,
            clickHandler: () => {
              const notificationToRemoveIndex = $rootScope.notifications.findIndex(
                (notification) => notification.mentionId === mentionId
              );
              if (notificationToRemoveIndex > -1) {
                $rootScope.notifications.splice(notificationToRemoveIndex, 1);
              }

              $rootScope.openNoteMention(noteId, mentionId);
            },
          });

          $rootScope.updateMentionsNotifications();
        });

        socket.on("CommCenterTicketUpdated", (data) => {
          $rootScope.$broadcast("comm_updated", data);
        });

        socket.on("CallCenterCallParticipantEvent", data => {
            $rootScope.$broadcast("CallCenterCallParticipantEvent", data);
        });

        socket.on("CallCenterCallStart", data => {
            $rootScope.$broadcast("CallCenterCallStart", data);
        });

        socket.on("CallCenterCallEnd", data => {
            console.log("got event CallCenterCallEnd");
            $rootScope.$broadcast("CallCenterCallEnd", data);
        });
        socket.on("CallCenterInboundCallRinging", (data) => {
            $rootScope.$broadcast("CallCenterInboundCallRinging", data);
        });
        socket.on("CommCenterTicketViewed", (data) => {
            $rootScope.$broadcast("comm_updated", data);
          });
    }


    return {
        init: Consts.socketApi ? init : () => {
            console.log('`SOCKET_URL` not defined in server env vars: socket connection is disabled.');
        },
        on: function (eventName, callback) {}
    };
}