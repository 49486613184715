import { Box, Flex, IconProps, Text } from "@chakra-ui/react";
import { ChronoUnit, Duration, Instant } from "@js-joda/core";
import React, { useEffect, useState } from "react";
import { Messages } from "../../../../core/api";
import { durationFormatter } from "../../../../shared/utils/duration-formatter";
import { phoneFormatter } from "../../../../shared/utils/phone-formatter";
import CallTicketEventCard from "./helpers/CallTicketEventCard/CallTicketEventCard";
import CallTicketParticipantCard from "../CallTicketParticipantCard";
import CallTicketColumn from "./helpers/CallTicketColumn";
import CallTicketRow from "./helpers/CallTicketRow";
import LiveBadge from "../LiveBadge";
import HeadphonesIcon from "../../../../shared/icons/HeadphonesIcon";
import BaselinePersonAddAlt1Icon from "../../../../shared/icons/BaselinePersonAddAlt1Icon";
import MicMuteFillIcon from "../../../../shared/icons/MicMuteFillIcon";
import DialpadIcon from "../../../../shared/icons/DialpadIcon";
import PhoneHangupIcon from "../../../../shared/icons/PhoneHangupIcon";
import ShareIcon from "../../../../shared/icons/ShareIcon";
import { CallSessionId, NoteSubjectId } from "../../../../shared/schema/schema";
import useActiveCall from "../../../../shared/hooks/useActiveCall";
import CallNotesSection from "./CallNotesSection";

const ICON_PROPS: IconProps = {
  cursor: "var(--icon-cursor)",
  boxSize: "var(--icon-box-size)",
  color: "var(--icon-color)",
};

interface Props {
  activeTicket: Messages["CommCenterTicket"] & {
    callSessionDetails: Messages["CallSessionDetails"];
  };
  noteSubjects: { label: string; value: NoteSubjectId }[];
  onClickHangup: (callSessionId: CallSessionId) => void;
}

function getCallDurationSeconds(callSessionDetails: Messages["CallSessionDetails"]): number {
  const firstEnterEvent = callSessionDetails.sessionParticipantEvents.find(
    (x) => x.data.eventStatus === "ENTER"
  );
  if (firstEnterEvent === undefined) {
    return 0;
  }
  return firstEnterEvent.createdAt.until(Instant.now(), ChronoUnit.SECONDS);
}

const LiveCallTicket = (props: Props) => {
  const { activeCall } = useActiveCall();
  const [durationSeconds, setDurationSeconds] = useState(
    getCallDurationSeconds(props.activeTicket.callSessionDetails)
  );

  const { callSessionDetails } = props.activeTicket;

  useEffect(() => {
    const interval = setInterval(() => {
      setDurationSeconds((prev) => {
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [activeCall]);

  const isSessiongJoined = () => {
    return activeCall?.ticketId === props.activeTicket.id;
  };

  const onClickHangup = () => {
    if (activeCall !== null) {
      props.onClickHangup(props.activeTicket.callSessionDetails.sessionId);
    }
  };

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      backgroundSize="60%"
      borderRadius="0 12px 12px 0"
      height="100%"
      gap={2}
      padding={2}
    >
      <CallTicketColumn>
        <CallTicketRow>
          <Flex py={"10px"} flexDirection={"column"} gap={"50px"}>
            <Flex flexDirection={"column"} gap={4} align={"center"}>
              <Text fontSize={"5xl"}>
                {durationFormatter.hours(Duration.ofSeconds(durationSeconds))}
              </Text>
              <Text>
                {phoneFormatter.isValidNumber(callSessionDetails.externalPhoneNumber)
                  ? phoneFormatter.formatNational(callSessionDetails.externalPhoneNumber)
                  : callSessionDetails.externalPhoneNumber}
              </Text>
              <LiveBadge />
            </Flex>
            <Box
              sx={{ "--icon-box-size": "40px", "--icon-color": "gray", "--icon-cursor": "pointer" }}
            >
              {isSessiongJoined() ? (
                <JoinedCallActions onClickHangup={onClickHangup} />
              ) : (
                <NotjoinedCallActions />
              )}
            </Box>
          </Flex>
        </CallTicketRow>
        <CallTicketRow>
          <CallTicketRow.Header>Add Your Notes</CallTicketRow.Header>
          <CallTicketRow.Content>
            <CallTicketRow.ContentRow>
              <CallNotesSection
                sessionDetails={callSessionDetails}
                noteSubjects={props.noteSubjects}
              />
            </CallTicketRow.ContentRow>
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>
      <CallTicketColumn>
        <CallTicketRow>
          <CallTicketRow.Header>Call Parties</CallTicketRow.Header>
          <CallTicketRow.Content>
            {callSessionDetails.sessionParticipantRoles.map((role, i) => (
              <CallTicketRow.ContentRow key={i.toString()}>
                <CallTicketParticipantCard entity={role} index={i} />
              </CallTicketRow.ContentRow>
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow>
          <CallTicketRow.Header>Call Events</CallTicketRow.Header>
          <CallTicketRow.Content>
            {callSessionDetails.sessionParticipantEvents.map((event, i) => (
              <CallTicketRow.ContentRow key={i.toString()}>
                <CallTicketEventCard event={event} />
              </CallTicketRow.ContentRow>
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>
    </Flex>
  );
};

const JoinedCallActions = (actions: { onClickHangup: () => void }) => {
  return (
    <Flex w={"full"} flexDirection={"column"} justifyContent={"center"} gap={2}>
      <Flex direction={"row"} justifyContent={"center"} gap={12}>
        <MicMuteFillIcon {...ICON_PROPS} />
        <Box></Box>
        <DialpadIcon {...ICON_PROPS} />
      </Flex>
      <Flex direction={"row"} justifyContent={"center"} gap={6}>
        <BaselinePersonAddAlt1Icon {...ICON_PROPS} />
        <PhoneHangupIcon {...ICON_PROPS} onClick={actions.onClickHangup} />
        <ShareIcon {...ICON_PROPS} />
      </Flex>
    </Flex>
  );
};

const NotjoinedCallActions = () => {
  return (
    <Flex w={"full"} flexDirection={"row"} justifyContent={"center"} gap={7}>
      <HeadphonesIcon {...ICON_PROPS} />
      <BaselinePersonAddAlt1Icon {...ICON_PROPS} />
    </Flex>
  );
};

export default LiveCallTicket;
