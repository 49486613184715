import { Box, Button, Center, Divider, Flex, Heading, Tag } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { match } from "ts-pattern";
import { Messages } from "../../../core/api";
import { Entity, EntityCardLink } from "../../../shared/components/EntityCard";
import SelectMenu from "../../../shared/components/SelectMenu";
import { TextShimmer } from "../../../shared/components/Shimmer";
import { createFilters } from "../../../shared/hooks/useFilters";
import AddRoundedIcon from "../../../shared/icons/AddRoundedIcon";
import MarkUnreadIcon from "../../../shared/icons/MarkUnreadIcon";
import {
  CallSessionId,
  CaregiverId,
  CommCenterLabelId,
  CommCenterTeamId,
  CommCenterTeamMemberId,
  CommCenterTicketId,
  NoteSubjectId,
  PatientId,
} from "../../../shared/schema/schema";
import { fmap } from "../../../shared/utils";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { Loadable } from "../../../shared/utils/loadable";
import CaregiverSelect from "../../caregiver/components/CaregiverSelect";
import PatientSelect from "../../patient/components/PatientSelect";
import {
  CommCenterRelatedVisit,
  CommCenterTicketStatus,
  getRelatedVisitFromTicket,
  getRelatedEntityFromTicket,
  NewTicketRequestBody,
  isChatMessagesTicket,
  isPhoneCallTicket,
} from "../utils/communication-utils";
import CallTicket from "./CallTicket";
import ChatMessages from "./ChatMessages";
import NewTicket from "./NewTicket";
import TicketsList from "./TicketsList";

interface Props {
  label: string | null;
  entity: Loadable<Entity>;
  settings: {
    assignedToId: CommCenterTeamMemberId | null;
    teamId: CommCenterTeamId;
    status: CommCenterTicketStatus;
  };
  tickets: Loadable<Messages["CommCenterTicket"][]>;
  teams: Messages["CommCenterTeamWithMembers"][];
  activeTicket: Messages["CommCenterTicket"] | null;
  onboardingStageName: string | null;
  labels: Messages["CommCenterLabel"][];
  initialLabelId: CommCenterLabelId | null;
  isNewTicketOpen: boolean;
  areActionsDisabled: boolean;
  noteSubjects: { label: string; value: NoteSubjectId }[];
  liveCallTicketIds: CommCenterTicketId[];
  entityCardAs?: React.ElementType<{ entity: Entity }>;
  onCreateNewTicket: (newTicket: NewTicketRequestBody) => void;
  onChangeSettings: (
    ticketId: CommCenterTicketId,
    settings: Messages["Partial<EditCommCenterTicketParams>"]
  ) => void;
  onClickMarkAsUnread: (ticketId: CommCenterTicketId) => void;
  onClickTicket: (ticketId: CommCenterTicketId) => void;
  onSubmitNewMessage: (ticketId: CommCenterTicketId, message: string) => void;
  onClickNewTicket: () => void;
  onRequestCloseNewTicket: () => void;
  onClickHangup: (callSessionId: CallSessionId) => void;
}

const TicketsBox = (props: Props) => {
  const { createSelectFilter } = createFilters<Messages["Partial<EditCommCenterTicketParams>"]>();

  const handleChange = <
    $Name extends keyof Messages["Partial<EditCommCenterTicketParams>"],
    $Option extends NonNullable<Messages["Partial<EditCommCenterTicketParams>"][$Name]>
  >(
    name: $Name,
    option: $Option | undefined
  ) => {
    if (props.activeTicket !== null) {
      props.onChangeSettings(props.activeTicket.id, { [name]: option });
    }
  };

  const activeTeam =
    props.teams.find((team) => team.id === props.settings.teamId) ?? props.teams.at(0);

  const assigneeFilter = createSelectFilter({
    label: "Assignee",
    name: "assignedToId",
    options:
      activeTeam === undefined
        ? []
        : activeTeam.members.map((member) => ({
            label: [member.firstName, member.lastName].join(" "),
            value: member.id,
          })),
    value: props.settings.assignedToId,
    disabled: props.areActionsDisabled,
    onChange: handleChange,
  });

  const teamFilter = createSelectFilter({
    label: "Team",
    name: "teamId",
    options: props.teams.map((team) => ({ label: team.name, value: team.id })),
    value: props.settings.teamId,
    disabled: props.areActionsDisabled,
    onChange: handleChange,
  });

  const statusFilter = createSelectFilter({
    name: "status",
    label: "Status",
    options: [
      { label: "New", value: "NEW" },
      { label: "In progress", value: "IN PROGRESS" },
      { label: "Resolved", value: "RESOLVED" },
    ],
    value: props.settings.status,
    disabled: props.areActionsDisabled,
    onChange: handleChange,
  });

  const handleSubmitNewMessage = async (message: string) => {
    if (props.activeTicket !== null) {
      props.onSubmitNewMessage(props.activeTicket.id, message);
    }
  };

  const handleClickMarkAsUnread = () => {
    if (props.activeTicket !== null) {
      props.onClickMarkAsUnread(props.activeTicket.id);
    }
  };

  const handleCreateNewTicket = (newTicket: NewTicketRequestBody) => {
    props.onCreateNewTicket(newTicket);
    props.onRequestCloseNewTicket();
  };

  const handleClickTicket = (ticketId: CommCenterTicketId) => {
    props.onClickTicket(ticketId);
    props.onRequestCloseNewTicket();
  };

  const handleEntityChange = (
    entityChange: Partial<{ caregiverId: CaregiverId | null; patientId: PatientId | null }>
  ) => {
    if (props.activeTicket !== null) {
      props.onChangeSettings(props.activeTicket.id, entityChange);
    }
  };

  const entityMatch = match(props.entity)
    .with({ type: "Loading" }, () => <TextShimmer width={300} height={40} />)
    .with({ type: "Resolved" }, ({ value }) => {
      return <TicketsBox.Entity entity={value} as={props.entityCardAs} />;
    })
    .with({ type: "Rejected" }, () => <>Failed to load entity</>)
    .exhaustive();

  const defaultValuesForNewTicket = {
    caregiverId:
      props.entity.type === "Resolved"
        ? props.entity.value.type === "Caregiver"
          ? props.entity.value.id
          : null
        : null,
    patientId:
      props.entity.type === "Resolved"
        ? props.entity.value.type === "Patient"
          ? props.entity.value.id
          : null
        : null,
    topic: props.entity.type === "Resolved" ? props.entity.value.type : "Caregiver",
  };

  const relatedEntity =
    props.activeTicket === null ? null : getRelatedEntityFromTicket(props.activeTicket);

  const caregiverSelectProps = {
    value:
      props.activeTicket?.topic === "Patient"
        ? relatedEntity?.type === "Caregiver"
          ? relatedEntity.id
          : null
        : defaultValuesForNewTicket.caregiverId,
    isDisabled: props.areActionsDisabled || props.activeTicket?.topic === "Caregiver",
  };

  const patientSelectProps = {
    value:
      props.activeTicket?.topic === "Caregiver"
        ? relatedEntity?.type === "Patient"
          ? relatedEntity.id
          : null
        : defaultValuesForNewTicket.patientId,
    isDisabled: props.areActionsDisabled || props.activeTicket?.topic === "Patient",
  };

  const relatedVisit = getRelatedVisitFromTicket(props.activeTicket);

  return (
    <TicketsBox.Root>
      <TicketsBox.Header>
        {entityMatch}
        {props.label && <TicketsBox.LabelName>{props.label}</TicketsBox.LabelName>}
        {props.onboardingStageName !== null && (
          <TicketsBox.StageName colorScheme="purple">
            {props.onboardingStageName}
          </TicketsBox.StageName>
        )}
        <TicketsBox.Settings>
          <TicketsBox.Actions>
            <Button
              leftIcon={<MarkUnreadIcon />}
              variant="outline"
              disabled={props.areActionsDisabled}
              onClick={handleClickMarkAsUnread}
            >
              Mark as unread
            </Button>
          </TicketsBox.Actions>
          <Box display={props.activeTicket?.topic === "Patient" ? "inherit" : "none"}>
            <CaregiverSelect
              value={caregiverSelectProps.value}
              isDisabled={caregiverSelectProps.isDisabled}
              onChange={(value) =>
                handleEntityChange({ caregiverId: value === null ? null : value.id })
              }
            />
          </Box>
          <Box display={props.activeTicket?.topic === "Caregiver" ? "inherit" : "none"}>
            <PatientSelect
              value={patientSelectProps.value}
              isDisabled={patientSelectProps.isDisabled}
              onChange={(value) =>
                handleEntityChange({ patientId: value === null ? null : value.id })
              }
            />
          </Box>
          <SelectMenu {...assigneeFilter} />
          <SelectMenu {...teamFilter} />
          <SelectMenu {...statusFilter} />
          <Button
            colorScheme="blue"
            leftIcon={<AddRoundedIcon />}
            disabled={props.isNewTicketOpen}
            onClick={props.onClickNewTicket}
          >
            New
          </Button>
        </TicketsBox.Settings>
        {fmap(relatedVisit, (vals) => (
          <TicketsBox.Settings>
            <TicketsBox.RelatedVisit {...vals} />
          </TicketsBox.Settings>
        ))}
      </TicketsBox.Header>

      <Divider />

      <TicketsBox.Body flex={1}>
        <Box w={375} height="var(--max-chat-height)" overflow="auto" flexShrink={0}>
          <TicketsList tickets={props.tickets} onClickTicket={handleClickTicket} />
        </Box>

        {props.isNewTicketOpen ? (
          <Center w="full" p={16}>
            <NewTicket
              initialLabelId={props.initialLabelId}
              defaultValues={defaultValuesForNewTicket}
              labels={props.labels}
              onCreateTicket={handleCreateNewTicket}
            />
          </Center>
        ) : (
          <Box flex={1}>
            {fmap(
              props.activeTicket,
              (ticket) =>
                (isChatMessagesTicket(ticket) && (
                  <ChatMessages activeTicket={ticket} onSubmitNewMessage={handleSubmitNewMessage} />
                )) ||
                (isPhoneCallTicket(ticket) && (
                  <CallTicket
                    liveCallTicketIds={props.liveCallTicketIds}
                    noteSubjects={props.noteSubjects}
                    onClickHangup={props.onClickHangup}
                    activeCallTicket={ticket}
                  />
                ))
            )}
          </Box>
        )}
      </TicketsBox.Body>
    </TicketsBox.Root>
  );
};

TicketsBox.Root = styled(Flex)`
  flex-direction: column;
  flex: 1;
`;

TicketsBox.Header = (props: { children: React.ReactNode }) => {
  return (
    <Flex wrap="wrap" p={4} gap={4} align="center">
      {props.children}
    </Flex>
  );
};
TicketsBox.Entity = (props: { entity: Entity; as?: React.ElementType<{ entity: Entity }> }) => {
  const EntityComponent = props.as ?? EntityCardLink;

  return (
    <Heading as="h3" size="md">
      <EntityComponent {...props} />
    </Heading>
  );
};

TicketsBox.RelatedVisit = (props: CommCenterRelatedVisit) => {
  const handleClick = () => {
    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: { type: "navigate", payload: { id: props.id, entity: props.type } },
      })
    );
  };

  return (
    <TicketsBox.ExternalEntityLabel onClick={handleClick} colorScheme="blue">
      {match(props)
        .with(
          { type: "VisitBroadcast" },
          (visitBroadcast) =>
            `This ticket is related to a Visit Broadcast (${
              visitBroadcast.id
            }) From ${dateFormatter.toDate(visitBroadcast.startTime)} - ${dateFormatter.toDate(
              visitBroadcast.endTime
            )}`
        )
        .with(
          { type: "VisitInstance" },
          (visitInstance) =>
            `This ticket is related to a Visit Instance (${
              visitInstance.id
            }) on ${dateFormatter.toDateTime(visitInstance.startTime)}`
        )
        .exhaustive()}
    </TicketsBox.ExternalEntityLabel>
  );
};

TicketsBox.LabelName = Tag;
TicketsBox.StageName = Tag;
TicketsBox.ExternalEntityLabel = styled(Tag)`
  cursor: pointer;
  padding: 5px;
`;
TicketsBox.Settings = (props: { children: React.ReactNode }) => {
  return (
    <Flex gap={2} flex={1} justifyContent="flex-end">
      {props.children}
    </Flex>
  );
};

TicketsBox.Actions = Box;

TicketsBox.Body = styled(Flex)`
  max-height: var(--max-chat-height);
  > * + * {
    border-left: 1px solid var(--chakra-colors-gray-100);
  }
`;

export default TicketsBox;
