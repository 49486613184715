import { IOnChangesObject } from "angular";
import {
  formatPhoneNumber,
  getLabelForNotIdentifiedPhoneNumber,
  hangupCall,
} from "../../call-center.utils";
import { CallCenterService } from "../../services/call-center.service";
import { InboundCallSocketPayload } from "../call-center.types";
import "./active-call-banner.component.scss";

interface ComponentOptions extends angular.IComponentOptions {
  $name: string;
}

//! @ngInject
class Controller implements ng.IComponentController {
  static readonly $name = "ActiveCallBanner";
  activeCall!: InboundCallSocketPayload & { status?: string };
  callDuration!: string;
  callHeader!: string;

  constructor(
    private $rootScope: angular.IRootScopeService,
    private $state: ng.ui.IStateService,
    private callCenterService: CallCenterService
  ) {}

  $onChanges(onChangesObj: IOnChangesObject): void {
    const callData = onChangesObj.activeCall.currentValue;

    if (callData === undefined || callData === null || callData.status !== "Active") {
      return;
    }
    this.activeCall = callData;

    switch (callData.additionalData.type) {
      case "Patient": {
        const patientName = callData.additionalData.patientName;
        const patientId = callData.additionalData.id;
        this.callHeader = `Patient - ${patientName} (${patientId})`;
        break;
      }
      case "AgencyMember": {
        const agencyMemberName = callData.additionalData.agencyMemberName;
        const agencyMemberId = callData.additionalData.id;
        this.callHeader = `Agency Member - ${agencyMemberName} (${agencyMemberId})`;
        break;
      }
      case "NotIdentifiedPhoneNumber": {
        const title = getLabelForNotIdentifiedPhoneNumber(
          callData.additionalData.notIdentifiedType
        );
        const formattedPhone = formatPhoneNumber(callData.additionalData.phoneNumber);
        this.callHeader = `${title} - ${formattedPhone}`;
        break;
      }
      default:
        this.callHeader = "";
    }
  }

  handleClick = () => {
    if (this.$rootScope.activeCall !== null) {
      this.$rootScope.activeCall.liveCallModalOpen = true;
    }
  };

  handleHangUp = () => {
    hangupCall(() => {
      if (this.$rootScope.activeCall) {
        this.callCenterService.hangupCall(this.activeCall.callSessionId);
        this.$rootScope.activeCall = null;
      }
    });
  };
}

export const ActiveCallBannerComponent: ComponentOptions = {
  $name: "activeCallBanner",
  templateUrl:
    "admin/modules/call-center/components/active-call-banner/active-call-banner.component.html",
  controller: Controller,
  controllerAs: "ctrl",
  bindings: {
    activeCall: "<",
  },
};
