import React from "react";
import type { CallSessionCallerType } from "../../../public/admin/modules/call-center/components/call-center.types";
import { useWebappChannelPayloadEvent } from "../shared/hooks/useWebappChannelEvent";
import webappChannel from "./webapp-channel";

export interface CallCenterUtils {
  answerIncomingCall(
    destination: string,
    onSessionConnectedCallback: (res: any) => void,
    onSessionTerminatedCallback: (res: any) => void
  ): void;
  startOutboundCall(
    destination: string,
    onSessionConnectedCallback: (res: any) => void,
    onSessionTerminatedCallback: (res: any) => void
  ): Promise<void>;

  hangupCall(hangupCallback: (res: any) => void): void;
  muteCall(muteCallback: (res: any) => void): void;
  unMuteCall(unMuteCallback: (res: any) => void): void;
  getLabelForNotIdentifiedPhoneNumber: (
    notIdentifiedType: CallSessionCallerType | null
  ) =>
    | "Caregiver"
    | "Patient"
    | "Not Identified Caregiver"
    | "Not Identified Patient"
    | "Not Identified Provider"
    | "Not Identified";
  formatPhoneNumber: (input: string) => string;
}

export const CallCenterUtilsContext = React.createContext<CallCenterUtils>(null as any);

export const CallCenterUtilsProvider = (props: { children: React.ReactNode }) => {
  const [callCenterUtils, setCallCenterUtils] = React.useState<CallCenterUtils>(null as any);

  useWebappChannelPayloadEvent({
    eventName: "CALL_CENTER_UTILS_SET",
    runInitial: true,
    onEvent: () => {
      const info = webappChannel.getPayload("CALL_CENTER_UTILS_SET");
      if (info !== undefined) {
        return setCallCenterUtils(info);
      }
    },
  });

  return (
    <CallCenterUtilsContext.Provider value={callCenterUtils}>
      {props.children}
    </CallCenterUtilsContext.Provider>
  );
};
