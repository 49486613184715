import React from "react";
import { match } from "ts-pattern";
import CallTicketEventCardRingingFrom from "./components/CallTicketEventCardRingingFrom";
import { CallSessionDetailsEvent } from "./call-ticket-events-typings";
import CallTicketEventCardRingingAt from "./components/CallTicketEventCardRingingAt";
import CallTicketEventCardEnter from "./components/CallTicketEventCardEnter";
import CallTicketEventCardIVR from "./components/CallTicketEventIVR";
import CallTicketEventCardLeave from "./components/CallTicketEventCardLeave";

interface Props {
  event: CallSessionDetailsEvent;
}

const CallTicketEventCard = ({ event }: Props) => {
  return match(event)
    .with({ data: { eventStatus: "RINGING_FROM" } }, (event) => (
      <CallTicketEventCardRingingFrom event={event} />
    ))
    .with({ data: { eventStatus: "RINGING_AT" } }, (event) => (
      <CallTicketEventCardRingingAt event={event} />
    ))
    .with({ data: { eventStatus: "ENTER" } }, (event) => <CallTicketEventCardEnter event={event} />)
    .with({ data: { eventStatus: "IVR" } }, (event) => <CallTicketEventCardIVR event={event} />)
    .with({ data: { eventStatus: "LEAVE" } }, (event) => <CallTicketEventCardLeave event={event} />)
    .exhaustive();
};

export default CallTicketEventCard;
