import { Flex, Text } from "@chakra-ui/react";
import { capitalize } from "lodash";
import React from "react";
import { match } from "ts-pattern";
import { Messages } from "../../../core/api";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { isCommCenterMessageAuthorTheSame } from "../utils/communication-utils";
import ChatMessage from "./ChatMessage";
import ChatMessageInput from "./ChatMessageInput";
import ClosedChatMessage from "./ClosedChatMessage";

interface Props {
  activeTicket: Messages["CommCenterTicket"];
  onSubmitNewMessage: (message: string) => void;
}

const ChatMessages = (props: Props) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const messages = [
    ...props.activeTicket.messages.map((payload) => {
      return { type: "Message" as const, payload };
    }),
    ...props.activeTicket.statusChanges.map((payload) => {
      return { type: "StatusChange" as const, payload };
    }),
    ...props.activeTicket.assigneeChanges.map((payload) => {
      return { type: "AssigneeChange" as const, payload };
    }),
  ].sort((a, b) => a.payload.createdAt.compareTo(b.payload.createdAt));

  React.useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  }, [props.activeTicket.messages]);

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      backgroundSize="60%"
      borderRadius="0 12px 12px 0"
      height="100%"
    >
      <Flex direction="column" ref={wrapperRef} gap={2} p={4} overflowY="auto" height="100%">
        {messages.map((message, i) => {
          switch (message.type) {
            case "Message": {
              const isTheSameAuthor = isCommCenterMessageAuthorTheSame({
                previous: props.activeTicket.messages[i - 1],
                next: message.payload,
              });

              return (
                <ChatMessage
                  key={`message-${message.payload.id}`}
                  message={message.payload}
                  displayEntityName={!isTheSameAuthor}
                />
              );
            }
            case "StatusChange": {
              return (
                <ChatStatusChangeEvent
                  key={`status-change-${message.payload.id}`}
                  event={message.payload}
                />
              );
            }
            case "AssigneeChange": {
              return (
                <ChatAssigneeChangeEvent
                  key={`assignee-change-${message.payload.id}`}
                  event={message.payload}
                />
              );
            }
          }
        })}
      </Flex>
      {props.activeTicket.status !== "RESOLVED" ? (
        <ChatMessageInput onSubmit={props.onSubmitNewMessage} />
      ) : (
        <ClosedChatMessage isOpen={props.activeTicket.status === "RESOLVED"} />
      )}
    </Flex>
  );
};

const EventRoot = (props: { children: React.ReactNode }) => {
  return (
    <Flex
      align="center"
      justifyContent="center"
      gap={1}
      p={3}
      my={2}
      mx="auto"
      bg="orange.100"
      w="fit-content"
      borderRadius="lg"
    >
      {props.children}
    </Flex>
  );
};

const ChatStatusChangeEvent = (props: { event: Messages["CommCenterTicketStatusChange"] }) => {
  const createdByName = match(props.event.createdBy)
    .with({ type: "System" }, () => "System")
    .with({ type: "Caregiver" }, { type: "TeamMember" }, ({ name }) => name)
    .exhaustive();

  return (
    <EventRoot>
      <Text color="black.500">{dateFormatter.toDateOrDateTime(props.event.createdAt)}</Text>
      <Text fontWeight="bold">{createdByName}</Text>
      <Text>has changed the ticket status to</Text>
      <Text fontWeight="bold">{capitalize(props.event.status)}</Text>
    </EventRoot>
  );
};

const ChatAssigneeChangeEvent = (props: { event: Messages["CommCenterTicketAssigneeChange"] }) => {
  return (
    <EventRoot>
      <Text color="black.500">{dateFormatter.toDateOrDateTime(props.event.createdAt)}</Text>
      {props.event.assignedTo !== null ? (
        <>
          <Text fontWeight="bold">{props.event.assignedTo.name}</Text>
          <Text>was assigned to this ticket by</Text>
          <Text>{props.event.createdBy.name}</Text>
        </>
      ) : (
        <>
          <Text>{props.event.createdBy.name}</Text>
          <Text>has unassigned this case</Text>
        </>
      )}
    </EventRoot>
  );
};

export default ChatMessages;
