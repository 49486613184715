import {
  CallSessionId,
  CallSessionParticipantId,
  CaregiverId,
  CommCenterTicketId,
  PatientId,
  VisitBroadcastId,
  VisitInstanceId,
} from "../shared/schema/schema";
import { ActiveCallData } from "./activeCall";
import { Messages } from "./api";
import type { DatabaseApiService } from "../../../public/admin/scripts/services/db";
import { CallCenterUtils } from "./callCenterUtils";

interface AuthInfo {
  authToken: string;
  refreshToken: string;
}

function createWebappChannel() {
  const channel = new BroadcastChannel("webapp_channel");

  // I HAVE NO IDEA WHY IT DOESN'T WORK WITH A SINGLE DECLARATION
  const channelx = new BroadcastChannel("webapp_channel");

  function getPayload<T extends keyof AngularJSPayload>(key: T): AngularJSPayload[T] | undefined {
    return (window as any).__ANGULARJS_PAYLOAD__?.[key];
  }

  function setPayload<T extends keyof AngularJSPayload>(key: T, payload: AngularJSPayload[T]) {
    if ((window as any).__ANGULARJS_PAYLOAD__ === undefined) {
      (window as any).__ANGULARJS_PAYLOAD__ = {};
    }

    (window as any).__ANGULARJS_PAYLOAD__[key] = payload;

    channelx.postMessage({ type: "PAYLOAD_SET", key });
  }

  function dispatch<T extends keyof AngularJSDispatch>(key: T, payload: AngularJSDispatch[T]) {
    channelx.postMessage({ type: "DISPATCH", key, payload });
  }

  return {
    channel,
    getPayload,
    setPayload,
    dispatch,
  };
}

export type EventFromReact =
  | {
      type: "navigate";
      payload:
        | { id: CaregiverId; entity: "Caregiver" }
        | { id: PatientId; entity: "Patient" }
        | { id: VisitInstanceId; entity: "VisitInstance" }
        | { id: VisitBroadcastId; entity: "VisitBroadcast" }
        | { id: string; entity: "Document" }
        | { id: PatientId; entity: "Note"; callback: () => void };
    }
  | {
      type: "setActiveCall";
      payload: ActiveCallData | null;
    };

export interface AngularJSPayload {
  AUTH_SET: AuthInfo | null;
  ACTIVE_CALL_SET: ActiveCallData | null;
  LOCAL_DB_SET: DatabaseApiService;
  CALL_CENTER_UTILS_SET: CallCenterUtils;
}

export interface AngularJSDispatch {
  SOCKET_EVENT_RECEIVED:
    | {
        type: "CommCenterTicketUpdated";
        payload: {
          ticketId: CommCenterTicketId;
        };
      }
    | {
        type: "CommCenterTyping";
        payload: {
          ticketId: CommCenterTicketId;
          millis: number;
        };
      }
    | {
        type: "CallCenterInboundCall";
        payload: {
          sessionToken: string;
          type: string;
          id: number;
        };
      }
    | {
        type: "CallCenterCallParticipantEvent";
        payload: {
          ticketId: CommCenterTicketId;
          eventStatusData: Messages["CallSessionParticipantEvent"]["data"];
        };
      }
    | {
        type: "CallCenterCallEnd";
        payload: {
          ticketId: CommCenterTicketId;
          callSessionId: CallSessionId;
        };
      }
    | {
        type: "CallCenterCallStart";
        payload: {
          ticketId: CommCenterTicketId;
          callSessionId: CallSessionId;
        };
      }
    | {
        type: "CallCenterOutboundCall";
        payload: {
          ticketId: CommCenterTicketId;
          callSessionId: CallSessionId;
          sessionToken: string;
          participantId: CallSessionParticipantId;
        };
      };
}

export default createWebappChannel();
