import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { match } from "ts-pattern";
import DialpadIcon from "../../../../../../../shared/icons/DialpadIcon";
import { components } from "../../../../../../../shared/schema/schema";
import { fmap } from "../../../../../../../shared/utils";
import { dateFormatter } from "../../../../../../../shared/utils/date-formatter";
import CallTicketIndexBadge from "../../../../CallTicketIndexBadge";
import { getParticipantDescription } from "../call-ticket-event-utils";
import { CallIVREvent } from "../call-ticket-events-typings";

interface Props {
  event: CallIVREvent;
}

const notIdentifiedCaregiverActionToTextMap: Record<
  components["schemas"]["NotIdentifiedType.Caregiver"]["action"],
  string
> = {
  SendSMSToApply: "Send SMS To Apply",
  VoiceMail: "Voice Mail",
};

const CallTicketEventCardIVR = ({ event }: Props) => {
  return (
    <Flex gap={2} alignItems={"center"}>
      <CallTicketIndexBadge>
        <DialpadIcon />
      </CallTicketIndexBadge>
      <Flex direction={"column"}>
        <Text color={"gray.400"}>{dateFormatter.toDateTime(event.createdAt)}</Text>
        <Flex gap={1}>
          <Text fontWeight={"bold"}>{getParticipantDescription(event.callSessionParticipant)}</Text>
          <Text>{formatIVREventData(event.data)}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const formatIVREventData = (data: CallIVREvent["data"]) =>
  match(data)
    .with(
      { callerType: "NotIdentifiedPhoneNumber" },
      (data) => fmap(data.digitPressed, (digit) => `pressed digit "${digit}".`) ?? `called IVR.`
    )
    .with(
      { callerType: "NotIdentifiedCaregiver" },
      (data) =>
        `pressed digit "${data.digitPressed}", action ${
          notIdentifiedCaregiverActionToTextMap[data.action]
        }.`
    )
    .with(
      { callerType: "NotIdentifiedInsuranceProvider" },
      { callerType: "NotIdentifiedPatient" },
      (data) => `pressed digit "${data.digitPressed}" and moved to team ${data.team}.`
    )
    .with({ callerType: "Patient" }, (data) => `chose status "${data.patientStatus}"`)
    .with({ callerType: "Caregiver" }, (data) => `${data.action}`)
    .exhaustive();

export default CallTicketEventCardIVR;
