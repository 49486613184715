import React from "react";
import { Messages } from "../../../core/api";
import { CallSessionId, CommCenterTicketId, NoteSubjectId } from "../../../shared/schema/schema";
import CompletedCallTicket from "./CallTicket/CompletedCallTicket";
import LiveCallTicket from "./CallTicket/LiveCallTicket";

interface Props {
  activeCallTicket: Messages["CommCenterTicket"] & {
    callSessionDetails: Messages["CallSessionDetails"];
  };
  noteSubjects: { label: string; value: NoteSubjectId }[];
  liveCallTicketIds: CommCenterTicketId[];
  onClickHangup: (callSessionId: CallSessionId) => void;
}

const CallTicket = (props: Props) => {
  if (props.liveCallTicketIds.includes(props.activeCallTicket.id)) {
    return (
      <LiveCallTicket
        onClickHangup={props.onClickHangup}
        activeTicket={props.activeCallTicket}
        noteSubjects={props.noteSubjects}
      />
    );
  }

  return <CompletedCallTicket activeTicket={props.activeCallTicket} />;
};

export default CallTicket;
