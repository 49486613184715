import { BoxProps, Flex, Link, Tag, Text } from "@chakra-ui/react";
import { phoneFormatter } from "@medflyt/webapp-react/src/shared/utils/phone-formatter";
import React from "react";
import { match, P } from "ts-pattern";
import { Messages } from "../../core/api";
import { TableCellBadge } from "../../modules/communication/components/TableCellBadge";
import { useEntityLink } from "../hooks/useEntityLink";
import { CaregiverId, PatientId } from "../schema/schema";
import { ImageCircleShimmer } from "./Shimmer";

export type CaregiverEntity = {
  type: "Caregiver";
  id: CaregiverId;
  displayId: number | null;
  photoUrl: string | null;
  fullName: string;
  status: Messages["CaregiverStatus"];
};

export type PatientEntity = {
  type: "Patient";
  id: PatientId;
  displayId: number | null;
  gender: string | null;
  fullName: string;
  status: Messages["PatientStatus"];
};

export type NotIdentifiedPhoneNumberEntity = {
  type: "NotIdentifiedPhoneNumber";
  phoneNumber: string;
};

function getColorByEntityStatus(status: Messages["CaregiverStatus"] | Messages["PatientStatus"]) {
  return match(status)
    .with("ACTIVE", () => "green")
    .with("JOIN_REQUEST", () => "purple")
    .with("ON_HOLD", () => "yellow")
    .with("ON_LEAVE", () => "yellow")
    .with("PENDING", () => "gray")
    .with("QUIT", () => "gray")
    .with("REJECTED", () => "gray")
    .with("SUSPENDED", () => "gray")
    .with("TERMINATED", () => "gray")
    .with("ACCEPTED", () => "gray")
    .with("DECEASED", () => "gray")
    .with("DISCHARGED", () => "gray")
    .with("DRAFT", () => "gray")
    .with("ELIGIBLE", () => "gray")
    .with("HOSPITALIZED", () => "gray")
    .with("PENDING_FILES", () => "gray")
    .with("REFERRAL", () => "gray")
    .with("VACATION", () => "gray")
    .exhaustive();
}

function getEntityTypeDisplayText(type: Pick<Entity, "type">): string {
  return match(type)
    .with({ type: "Caregiver" }, { type: "Patient" }, (entity) => entity.type)
    .with({ type: "NotIdentifiedPhoneNumber" }, () => "Not Indentified")
    .exhaustive();
}

export type Entity = CaregiverEntity | PatientEntity | NotIdentifiedPhoneNumberEntity;

export type EntityWithStatus<$Entity extends Entity> = $Entity & {
  status: {
    Caregiver: Messages["CaregiverStatus"];
    Patient: Messages["PatientStatus"];
    NotIdentifiedPhoneNumber: null;
  }[$Entity["type"]];
};

interface Props {
  entity: Entity;
  boxProps?: BoxProps;
}

const EntityCard = (props: Props) => {
  const { entity, boxProps } = props;

  return (
    <Flex gap={3} {...boxProps}>
      <ImageCircleShimmer size={35} src={getEntityPhotoUrl(entity)} />
      <Flex gap={1} direction="column">
        <Flex>
          {match(entity)
            .with({ type: "NotIdentifiedPhoneNumber" }, (en) => (
              <Text>{phoneFormatter.formatNationalIfValid(en.phoneNumber)}</Text>
            ))
            .with({ type: "Caregiver" }, { type: "Patient" }, (en) => (
              <Text>
                {en.fullName} {`${en.displayId ?? en.id}`}
              </Text>
            ))
            .exhaustive()}
        </Flex>
        <Flex>
          {match(props.entity)
            .with({ type: "Caregiver" }, { type: "Patient" }, (entity) => (
              <TableCellBadge color={getColorByEntityStatus(entity.status)} text={entity.status} />
            ))
            .otherwise(() => null)}

          <Tag size="sm" color="gray.700" bg="transparent">
            {getEntityTypeDisplayText(entity)}
          </Tag>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const EntityCardLink = (
  props: Props & {
    onClick?: () => void;
  }
) => {
  const { open } = useEntityLink();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (props.entity.type !== "NotIdentifiedPhoneNumber") {
      open({ event, entity: props.entity });

      if (props.onClick !== undefined) {
        props.onClick();
      }
    }
  };

  const boxProps: BoxProps =
    props.entity.type !== "NotIdentifiedPhoneNumber"
      ? {
          as: Link,
          onClick: handleClick,
          p: 3,
          borderRadius: "lg",
          _hover: { bg: "gray.100", color: "black", textDecoration: "none" },
          ...props.boxProps,
        }
      : {};

  return <EntityCard {...props} boxProps={boxProps} />;
};

export const PatientPhotoUrl = {
  M: "/admin/images/patient-men.png",
  F: "/admin/images/patient-women.png",
};

export function getEntityPhotoUrl(
  entity:
    | Pick<CaregiverEntity, "photoUrl">
    | Pick<PatientEntity, "gender">
    | NotIdentifiedPhoneNumberEntity
) {
  const blank = "admin/images/blank-profile.jpg";

  const url = match(entity)
    .with({ photoUrl: P.not(null) }, ({ photoUrl }) => photoUrl)
    .with({ gender: "F" }, () => PatientPhotoUrl.F)
    .with({ gender: "M" }, () => PatientPhotoUrl.M)
    .otherwise(() => blank);

  return url ?? blank;
}

export default EntityCard;
