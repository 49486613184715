import { Text, Flex } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../../core/api";
import { dateFormatter } from "../../../../shared/utils/date-formatter";

interface Props {
  note: Messages["Note"];
  agencyMembers: { [key: string]: string };
}

const CallNoteCard = ({ note, agencyMembers }: Props) => {
  return (
    <Flex direction={"column"}>
      <Text>
        <b>Subject:</b> {note.subject?.name ?? ""}
      </Text>
      <Text>
        <b>Content:</b> {note.noteRichText}
      </Text>
      <Flex color={"gray.500"} gap={2}>
        <Text>{dateFormatter.toDate(note.createdAt)}</Text>
        <Text>{agencyMembers[`${note.createdBy}`]}</Text>
      </Flex>
    </Flex>
  );
};

export default CallNoteCard;
