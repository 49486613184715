import { Box, Flex, Tag, Text } from "@chakra-ui/react";
import { Duration, Instant } from "@js-joda/core";
import React from "react";
import { Messages } from "../../../core/api";
import { CommCenterTicketId } from "../../../shared/schema/schema";

interface Props {
  ticket: Messages["CommCenterTicket"];
  onClick: (ticketId: CommCenterTicketId) => void;
}

const TicketsItem = (props: Props) => {
  const totalUnreadMessages = props.ticket.messages.filter(
    (message) => message.createdBy.type === "Caregiver" && message.readAt === null
  ).length;

  const lastMessage = props.ticket.messages.at(-1);
  const lastMessageSentTime = lastMessage?.createdAt ?? props.ticket.createdAt;
  const lastMessageText =
    lastMessage?.payload[0].type === "TEXT" ? lastMessage.payload[0].message : "";
  const assigneeName = props.ticket.assignedTo?.name ?? "Unassigned";
  const timeAgoH = Duration.between(
    Instant.parse(lastMessageSentTime.toString()),
    Instant.now()
  ).toHours();
  const timeAgoMin = Duration.between(
    Instant.parse(lastMessageSentTime.toString()),
    Instant.now()
  ).toMinutes();

  return (
    <Box
      p={2}
      cursor="pointer"
      _hover={{ backgroundColor: "var(--chakra-colors-gray-100)" }}
      onClick={() => props.onClick(props.ticket.id)}
    >
      <Tag mb={1}>{props.ticket.label?.name}</Tag>
      <Flex justifyContent="space-between">
        <Text>{assigneeName}</Text>
        <Text fontSize="sm" color="gray">
          {timeAgoH === 0
            ? timeAgoMin === 0
              ? `Just Now`
              : `${timeAgoMin} minutes ago`
            : `${timeAgoH}h ago`}
        </Text>
      </Flex>
      <Flex mt={2} ml={2}>
        <Tag rounded="full" bg="blue.500" color="white" mr={2} hidden={totalUnreadMessages === 0}>
          {totalUnreadMessages}
        </Tag>
        <Text color="gray" noOfLines={1}>
          {lastMessageText}
        </Text>
      </Flex>
    </Box>
  );
};

export default TicketsItem;
