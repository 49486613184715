import { Button, Flex, FormControl, Text, Textarea, useToast } from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Messages } from "../../../../core/api";
import { createFilters } from "../../../../shared/hooks/useFilters";
import { NoteSubjectId } from "../../../../shared/schema/schema";
import SelectMenu from "../../../../shared/components/SelectMenu";
import { zodResolver } from "@hookform/resolvers/zod";
import { fmap } from "../../../../shared/utils";
import useApi from "../../../../shared/hooks/useApi";
import useAuth from "../../../../shared/hooks/useAuth";

const createNoteSchema = z.object({
  text: z.string().min(4),
  subjectId: z
    .number()
    .nullable()
    .transform((x) => fmap(x, NoteSubjectId.wrap)),
});

type CreateNoteForm = z.infer<typeof createNoteSchema>;

interface Props {
  sessionDetails: Messages["CallSessionDetails"];
  noteSubjects: { label: string; value: NoteSubjectId }[];
}

const CallNotesSection = ({ sessionDetails, noteSubjects }: Props) => {
  const toast = useToast();
  const { api } = useApi();
  const { authData } = useAuth();
  const { control, handleSubmit, setValue, getValues, reset, formState } = useForm<CreateNoteForm>({
    resolver: zodResolver(createNoteSchema),
    defaultValues: {
      subjectId: null,
      text: "",
    },
  });

  const { createSelectFilter } = createFilters<CreateNoteForm>();
  const subjectIdSelectFilter = createSelectFilter({
    name: "subjectId",
    disabled: false,
    label: "Note subject",
    options: noteSubjects,
    onChange: (name, selected) => setValue(name, selected ?? null),
    value: getValues().subjectId,
  });

  const handleValidSubmit = async (data: CreateNoteForm) => {
    const patient = sessionDetails.sessionParticipantRoles.find((x) => x.type === "Patient") as
      | Messages["CallSessionParticipant.Patient"]
      | undefined;
    const caregiver = sessionDetails.sessionParticipantRoles.find((x) => x.type === "Caregiver") as
      | Messages["CallSessionParticipant.Caregiver"]
      | undefined;
    await api.post("./call_center/:callSessionId/note", {
      path: {
        callSessionId: sessionDetails.sessionId,
      },
      body: {
        status: "NONE",
        noteRichText: data.text,
        subject: data.subjectId ?? undefined,
        noteType: "phone_call",
        patientId: patient?.patientId,
        caregiverId: caregiver?.caregiverId,
        agencyMemberIds: authData.data?.agencyMember.id ? [authData.data?.agencyMember.id] : [],
      },
    });
    reset();
    toast({
      position: "top-right",
      status: "success",
      title: "Note added successfully",
    });
  };
  return (
    <Flex w={"full"} direction={"column"} gap={4} fontSize={"md"} alignItems={"center"}>
      <FormControl>
        <Controller
          control={control}
          name="text"
          render={({ field }) => (
            <>
              <Textarea w={"100%"} placeholder={"Write your note..."} {...field}></Textarea>
              <Text color={"red"}>{formState.errors.text?.message}</Text>
            </>
          )}
        />
      </FormControl>
      <FormControl>
        <SelectMenu closeOnSelect={true} buttonProps={{ w: "full" }} {...subjectIdSelectFilter} />
        <Text color={"red"}>{formState.errors.subjectId?.message}</Text>
      </FormControl>
      <FormControl>
        <Button colorScheme={"blue"} type={"submit"} onClick={handleSubmit(handleValidSubmit)}>
          Add Note
        </Button>
      </FormControl>
    </Flex>
  );
};

export default CallNotesSection;
