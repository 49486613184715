import { match, P } from "ts-pattern";
import { Messages } from "../../../core/api";
import {
  EntityWithStatus,
  Entity,
  CaregiverEntity,
  PatientEntity,
} from "../../../shared/components/EntityCard";
import { PatientId } from "../../../shared/schema/schema";
import { ViewFax } from "../components/ViewFaxButton";

export const getRelatedEntityFromFax = (
  fax: Messages["DashboardFax"]
): EntityWithStatus<Entity> => {
  return match(fax)
    .with(
      { relatedEntityType: "Caregiver", relatedCaregiver: P.not(null) },
      ({ relatedCaregiver }): EntityWithStatus<CaregiverEntity> => ({
        type: "Caregiver",
        displayId: relatedCaregiver.displayId,
        fullName: relatedCaregiver.name,
        id: relatedCaregiver.id,
        status: relatedCaregiver.status,
        photoUrl: relatedCaregiver.photoUrl,
      })
    )
    .with(
      { relatedEntityType: "Patient", relatedPatient: P.not(null) },
      ({ relatedPatient }): EntityWithStatus<PatientEntity> => ({
        type: "Patient",
        displayId: relatedPatient.displayId,
        fullName: relatedPatient.name,
        id: relatedPatient.id,
        status: relatedPatient.status,
        gender: relatedPatient.gender,
      })
    )
    .otherwise(() => {
      return {
        type: "Patient",
        displayId: null,
        fullName: "Not Assigned",
        id: PatientId.wrap(0),
        status: "DRAFT",
        gender: null,
      };
    });
};

export const getViewFaxButton = (fax: Messages["DashboardFax"]): ViewFax => {
  return {
    fax: fax,
    onSelectView: () => {
      window.dispatchEvent(
        new CustomEvent("from-webapp-react", {
          detail: { type: "navigate", payload: { id: fax.id, entity: "Document" } },
        })
      );
    },
  };
};
