import React from "react";
import { useWebappChannelPayloadEvent } from "../shared/hooks/useWebappChannelEvent";
import {
  CallSessionId,
  CallSessionParticipantId,
  CommCenterTicketId,
} from "../shared/schema/schema";
import type { InboundCallSocketPayloadAdditionalData } from "../../../public/admin/modules/call-center/components/call-center.types";
import webappChannel from "./webapp-channel";

export interface ActiveCallData {
  ticketId: CommCenterTicketId;
  participantId: CallSessionParticipantId;
  status: "Ringing" | "Active";
  liveCallModalOpen?: boolean;
  sessionToken: string;
  callSessionId: CallSessionId;
  additionalData: InboundCallSocketPayloadAdditionalData;
}

export const ActiveCallContext = React.createContext<{
  activeCall: ActiveCallData | null;
  setActiveCall: (call: ActiveCallData | null) => void;
}>({
  activeCall: null,
  setActiveCall: (_: ActiveCallData | null) => {
    // Do nothing now
  },
});

export const ActiveCallProvider = (props: { children: React.ReactNode }) => {
  const [activeCall, setActiveCall] = React.useState<ActiveCallData | null>(null);

  useWebappChannelPayloadEvent({
    eventName: "ACTIVE_CALL_SET",
    runInitial: true,
    onEvent: () => {
      const info = webappChannel.getPayload("ACTIVE_CALL_SET") ?? null;
      return setActiveCall(info);
    },
  });

  const setWebappActiveCall = (activeCall: ActiveCallData | null) => {
    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: {
          type: "setActiveCall",
          payload: activeCall,
        },
      })
    );
  };

  return (
    <ActiveCallContext.Provider value={{ activeCall, setActiveCall: setWebappActiveCall }}>
      {props.children}
    </ActiveCallContext.Provider>
  );
};
