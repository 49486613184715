import { WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { FaPhoneSlash, FaVoicemail } from "react-icons/fa";
import { match, P } from "ts-pattern";
import { Messages } from "../../../core/api";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import useCommCenterCallRecording from "../hooks/useCommCenterCallRecording";
import CallTicketRecording from "./CallTicket/helpers/CallTicketEventCard/CallTicketRecording";
import LiveBadge from "./LiveBadge";

interface Props {
  callSessionDetails: Messages["CommCenterTicket"]["callSessionDetails"];
  ticketId: CommCenterTicketId;
  liveCallTicketIds: CommCenterTicketId[];
}

const PhoneCallPreviewCell = (props: Props) => {
  const recordingState = useCommCenterCallRecording({
    callSessionDetails: props.callSessionDetails,
    ticketId: props.ticketId,
  });
  return match(props.callSessionDetails)
    .with(P.nullish, () => <span>No Status</span>)
    .with(P.not(P.nullish), (call) =>
      props.liveCallTicketIds.includes(props.ticketId) ? (
        <LiveBadge />
      ) : (
        formatCallDetails(call, recordingState)
      )
    )
    .exhaustive();
};

function formatCallDetails(
  call: Messages["CallSessionDetails"],
  recordingState: ReturnType<typeof useCommCenterCallRecording>
) {
  return match(call)
    .with({ callResult: "COMPLETE" }, () => (
      <Flex alignItems={"center"} gap={4}>
        <Text>Completed Call</Text>
        <Flex alignItems={"inherit"} gap={1} onClick={(e) => e.stopPropagation()}>
          <CallTicketRecording state={recordingState} />
        </Flex>
      </Flex>
    ))
    .with({ callResult: "FAIL" }, () => (
      <Flex gap={1} color={"red"}>
        <WarningTwoIcon />
        Failed Call
      </Flex>
    ))
    .with({ callResult: "MISSED_CALL" }, () => (
      <Flex gap={1} color={"red"}>
        <FaPhoneSlash />
        Missed Call
      </Flex>
    ))
    .with({ callResult: "RETURN_CALL" }, () => <Flex>Return Call</Flex>)
    .with({ callResult: "VOICE_MAIL" }, () => (
      <Flex color={"yellow.400"} gap={1}>
        <FaVoicemail size={"16px"} />
        Voice Mail
      </Flex>
    ))
    .with({ callResult: P.nullish }, () => <Flex>No Status</Flex>)
    .exhaustive();
}

export default PhoneCallPreviewCell;
