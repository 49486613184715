import { CallSessionCallerType } from "./components/call-center.types";
import * as phoneUtils from "../../scripts/utils/phoneUtils";

const Cloudonix = (window as any).Cloudonix;

export function answerIncomingCall(
  destination: string,
  onSessionConnectedCallback: (res: any) => void,
  onSessionTerminatedCallback: (res: any) => void
) {
  Cloudonix.sessionEvents.onSessionConnected((res) => {
    onSessionConnectedCallback(res);
  });
  Cloudonix.sessionEvents.onSessionTerminated((res) => {
    Cloudonix.sipStopCall();
    onSessionTerminatedCallback(res);
  });
  Cloudonix.sipStartCall(destination);
}

export async function startOutboundCall(
  destination: string,
  onSessionConnectedCallback: (res: any) => void,
  onSessionTerminatedCallback: (res: any) => void
) {
  Cloudonix.sessionEvents.onSessionConnected((res) => {
    console.log("connected");
    onSessionConnectedCallback(res);
  });
  Cloudonix.sessionEvents.onSessionTerminated((res) => {
    console.log("terminated");
    onSessionTerminatedCallback(res);
  });
  Cloudonix.sipStartCall(`999${destination}`);
}

export function hangupCall(hangupCallback: (res: any) => void) {
  Cloudonix.sessionEvents.onSessionTerminated((res) => {
    hangupCallback(res);
  });
  Cloudonix.sipStopCall();
}

export function muteCall(muteCallback: (res: any) => void) {
  Cloudonix.sessionEvents.onLocalMuteToggle((res) => {
    muteCallback(res);
  });
  Cloudonix.sipToggleMute();
}

export function unMuteCall(unMuteCallback: (res: any) => void) {
  Cloudonix.sessionEvents.onLocalMuteToggle((res) => {
    unMuteCallback(res);
  });
  Cloudonix.sipToggleMute();
}

export const getLabelForNotIdentifiedPhoneNumber = (
  notIdentifiedType: CallSessionCallerType | null
) => {
  switch (notIdentifiedType) {
    case "Caregiver":
      return "Caregiver";
    case "NotIdentifiedCaregiver":
      return "Not Identified Caregiver";
    case "NotIdentifiedPatient":
      return "Not Identified Patient";
    case "NotIdentifiedProvider":
      return "Not Identified Provider";
    case "Patient":
      return "Patient";
    case "NotIdentifiedPhoneNumber":
    case null:
      return "Not Identified";
  }
};

export const formatPhoneNumber = (input: string) => {
  if (phoneUtils.default.isValidNumber(input)) {
    return phoneUtils.default.formatNational(input);
  }

  return input;
};
