import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../query-keys";
import useApi from "./useApi";
import useMultipleSocketEvent from "./useMultipleSocketEvent";

const useLiveCallTicketIds = () => {
  const { api } = useApi();
  const liveCallTickets = useQuery({
    queryKey: queryKeys.commCenter.liveCallSessions(),
    queryFn: () => api.get("./call_center_active_call_sessions", {}),
    select: (data) => data.ticketsIds,
  });

  useMultipleSocketEvent({
    keys: [
      "CallCenterCallEnd",
      "CallCenterCallStart",
      "CallCenterInboundCall",
      "CallCenterOutboundCall",
      "CommCenterTicketUpdated",
    ],
    onEvent: () => liveCallTickets.refetch(),
  });

  return liveCallTickets;
};

export default useLiveCallTicketIds;
