import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { CallSessionId, CommCenterTicketId } from "../schema/schema";
import useActiveCall from "./useActiveCall";
import useApi from "./useApi";
import useCallCenterUtils from "./useCallCenterUtils";
import useSocketEvent from "./useSocketEvent";

const usePhoneNumberDialer = () => {
  const { api } = useApi();
  const { activeCall, setActiveCall } = useActiveCall();
  const callCenterUtils = useCallCenterUtils();
  const [newCallFromDial, setNewCallFromDial] = useState<{
    ticketId: CommCenterTicketId;
    sessionId: CallSessionId;
  } | null>(null);
  const [gotNewDialSocket, setGotNewDialSocket] = useState(false);
  const [phoneNumberDialed, setPhoneNumberDialed] = useState("");

  const notifyServer = useMutation({
    mutationKey: ["outbound_session_connected"],
    mutationFn: (callSessionId: CallSessionId) =>
      api
        .post("./call_center/:callSessionId/outbound_session_connected", {
          path: {
            callSessionId,
          },
        })
        .then(() => {
          if (activeCall !== null) {
            setActiveCall({ ...activeCall, status: "Active" });
          }
        }),
  });

  useEffect(() => {
    if (
      gotNewDialSocket &&
      newCallFromDial !== null &&
      !notifyServer.isLoading &&
      !notifyServer.isSuccess &&
      activeCall !== null
    ) {
      notifyServer.mutate(newCallFromDial.sessionId);
    }
  }, [activeCall, gotNewDialSocket, newCallFromDial, notifyServer]);

  useSocketEvent({
    key: "CallCenterOutboundCall",
    onEvent: (event) => {
      setNewCallFromDial({
        sessionId: event.callSessionId,
        ticketId: event.ticketId,
      });
      setActiveCall({
        participantId: event.participantId,
        sessionToken: event.sessionToken,
        status: "Ringing",
        ticketId: event.ticketId,
        callSessionId: event.callSessionId,
        liveCallModalOpen: true,
        additionalData: {
          type: "NotIdentifiedPhoneNumber",
          notIdentifiedType: "NotIdentifiedPhoneNumber",
          phoneNumber: phoneNumberDialed,
        },
      });
    },
  });

  const dialNumber = (E164Number: string) => {
    setPhoneNumberDialed(E164Number);
    console.log(`Calling: ${E164Number}`);
    callCenterUtils.startOutboundCall(
      E164Number,
      // connected
      (_res) => setGotNewDialSocket(true),
      // terminated
      (_res) => setActiveCall(null)
    );
  };

  return { dialNumber };
};

export default usePhoneNumberDialer;
