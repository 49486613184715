import React, { useCallback, useEffect, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import useSound from "use-sound";
import { Howl } from "howler";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";
import Backward15SecondsIcon from "../../icons/Backward15SecondsIcon";
import Forward15SecondsIcon from "../../icons/Forward15SecondsIcon";

interface Props {
  src: string;
  ratesOptions?: number[];
}

const defaultRates = [0.25, 0.5, 1, 1.5, 2];

const AudioPlayer = ({ src, ratesOptions = defaultRates }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [seconds, setSeconds] = useState<number | null>(null);
  const [rate, setRate] = useState<number>(1);
  const [, { duration, sound: untypedSound }] = useSound(src);
  const sound = untypedSound as Howl | null;

  const updateData = useCallback(() => {
    if (sound !== null) {
      setLoaded(true);
      setSeconds(sound.seek());
      setIsPlaying(sound.playing());
    }
  }, [sound, setLoaded, setSeconds, setIsPlaying]);

  useEffect(() => {
    const interval = setInterval(updateData, 500);
    return () => {
      clearInterval(interval);
      sound?.stop();
    };
  }, [sound, updateData]);

  const handleClickPlay = () => {
    if (loaded && sound !== null) {
      setIsPlaying(true);
      sound.play();
    }
  };

  const handleClickPause = () => {
    if (loaded && sound !== null) {
      setIsPlaying(false);
      sound.pause();
    }
  };

  const handleClickForward15Secs = () => {
    sound?.seek(Math.min(sound.seek() + 15, duration ?? 0));
  };

  const handleClickBackward15Secs = () => {
    sound?.seek(Math.max(sound.seek() - 15, 0));
  };

  const updateRate = () => {
    if (loaded && sound !== null) {
      const newRate =
        ratesOptions[(ratesOptions.findIndex((x) => x === rate) + 1) % ratesOptions.length];
      setRate(newRate);
      sound?.rate(newRate);
    }
  };

  const handleOnChange = (n: number) => {
    if (sound !== null) {
      sound?.pause();
      sound?.seek(n);
      updateData();
    }
  };

  const handleOnChangeEnd = (n: number) => {
    if (sound !== null) {
      sound.seek(n);
      sound.play();
      updateData();
    }
  };

  return !loaded ? (
    <Spinner />
  ) : (
    <Flex gap={4} align="center">
      <Backward15SecondsIcon cursor={"pointer"} boxSize={5} onClick={handleClickBackward15Secs} />
      {isPlaying ? (
        <FaPause cursor={"pointer"} onClick={handleClickPause} />
      ) : (
        <FaPlay cursor={"pointer"} onClick={handleClickPlay} />
      )}
      <Slider
        step={0.1}
        focusThumbOnChange={false}
        width={"150px"}
        value={seconds ?? 0}
        min={0}
        max={(duration ?? 0) / 1000}
        onChange={handleOnChange}
        onChangeEnd={handleOnChangeEnd}
      >
        <SliderTrack>
          <SliderFilledTrack bg={"blue.500"} cursor={"pointer"} />
        </SliderTrack>
        <SliderThumb bg={"black"} cursor={"pointer"} />
      </Slider>
      <Text cursor={"pointer"} onClick={updateRate}>
        <b>x{rate}</b>
      </Text>
      <Forward15SecondsIcon cursor={"pointer"} boxSize={5} onClick={handleClickForward15Secs} />
    </Flex>
  );
};

export default AudioPlayer;
